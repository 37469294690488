import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import Project from '@/views/Project.vue'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [      
      { path: '/', name: 'Home',  component: Home  },
      { path: '/:project', name: 'Project',  component: Project, props: true },
    ]
})

export default router