<!--
Copyright (C) 2020, Nicolas Morales Escobar. All rights reserved.
-->
<template>

    <section class="main-container" :style="{ 'background-color': bgColor }">
        <div class="project-title">
            {{title}}
        </div>

        <img class="project-image" v-bind:src=imgSrc>

        <div class="project-description">
            {{description}}
        </div>
        <div class="tags-container">
            <skill-tag class="tag" v-for="(item, index) in tags" :key="index" 
                            v-bind:title="item.name"
                            v-bind:color="item.color">
            </skill-tag>
        </div>
        <div v-if="link != null && link != ''" class="button-container">
            <button-component :text="'Github'"  v-on:clicked="openLink(link)"></button-component>
        </div>
    </section>

</template>

<script>
    import Controller from '@/../lib/controller'
    
    import skillTag from '@/components/SkillTag.vue'
    import buttonComponent from '@/components/ButtonComponent.vue'

    class ProjectPerviewController extends Controller {

        constructor( name, subComponentList = []) {

            super( name, subComponentList );

            this.vm = {
                maxWidth: '200px'
            }
            this.props = {

                title: String,
                imgSrc: String,
                description: String,
                bgColor: String,
                tags: Array,
                link: String
            }
        }

        openLink (link) {

            window.open(link);
        }
    }

    export default new ProjectPerviewController('projectPreview', { skillTag, buttonComponent } );

</script>

<style scoped>

.main-container {
    word-wrap: break-word;
    
    border-radius: 25px;
    
    
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3); 
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);
}

.project-title {

    min-height: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    
    font-size: calc(15px + 1vw);
}

.project-image {

    max-width: 95%;
    height: auto;
}

.project-description {

    min-height: 50px;
    padding: 25px;
    text-align: left;
    
    font-family: YanoneKaffeesatz-Light;
}
.tags-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    padding: 0px 25px 25px;
}

.button-container {

    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}
</style>