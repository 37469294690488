<!--
Copyright (C) 2020, Nicolas Morales Escobar. All rights reserved.
-->
<template>
    <section class="index-nav" :style="containerStyle">
        <div class="top-container">

            <div id="name" class="name-container" @click="scrollToTop()">{{name}}</div>
            
            <div class="elements-container">
                <div>
                    <index-item v-for="(item, index) in elements" :key="index" :title="item.name" v-on:clicked="scrollTo(item.name)"></index-item>
                </div>
                <div>
                    <index-item v-for="(item, index) in extraItems" :key="index" :title="item.name" v-on:clicked="scrollTo(item.name)"></index-item>
                </div>
                
                <custom-button style="margin-top: 20px" :text="'Resume'" v-on:clicked="downloadResume()"></custom-button>
            </div>
        </div>

        <div class="bottom-container">
            <div class="contact-title">Contact Information:</div>
            <div class="contact-container">
                <div>{{place}}</div>
                <div>{{email}}</div>
            </div>

            <div>
                <sidenav-icon :iconType="icons.github.type" :baseIconColor="baseIconColor" :hoverColor="icons.github.hoverColor" v-on:clicked="openLink(githubLink)"></sidenav-icon>
                <sidenav-icon :iconType="icons.linkedin.type" :baseIconColor="baseIconColor" :hoverColor="icons.linkedin.hoverColor" v-on:clicked="openLink(linkedinLink)"></sidenav-icon>
                <sidenav-icon :iconType="icons.mail.type" :baseIconColor="baseIconColor" :hoverColor="icons.mail.hoverColor" v-on:clicked="sendMail(email)"></sidenav-icon>
            </div>
        </div>
    </section>
</template>

<script>
    import Controller from '@/../lib/controller'
    import indexItem from '@/components/IndexItem.vue'
    import sidenavIcon from '@/components/SidenavIcon.vue'
    import customButton from '@/components/ButtonComponent.vue'

    class SidenavIndexController extends Controller {

        constructor( name, subComponentList = []) {

            super( name, subComponentList );

            this.vm = {
                
                icons: {
                    github: {
                        type: 'fab fa-github',
                        hoverColor: '#6f42c1'
                    },
                    linkedin: {
                        type: 'fab fa-linkedin',
                        hoverColor: '#2867B2'
                    },
                    mail: {
                        type: 'fas fa-envelope',
                        hoverColor: '#F0D734'
                    }
                }
            }

            this.props = {
                name: String,
                elements: Array,
                extraItems: Array,
                githubLink: String,
                linkedinLink: String,
                email: String,
                place: String,
                resume: String,
                bgColor: String,
                baseIconColor: String,
                fontColor: String
            }

            this.computed = {
                containerStyle: function() {
                    return {
                        '--background-color': this.bgColor,
                        '--color': this.fontColor
                    }
                }
            }

        }

        scrollTo (id) {
            
            this.$emit(`scroll`, id.replace(/\s+/g, ''));
        }

        scrollToTop () {
            
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        openLink (link) {

            window.open(link);
        }

        sendMail (email) {

            window.location.href = `mailto:${email}`;
        }

        downloadResume () {

            window.open(this.resume, 'scrollbars=yes,fullscreen=yes,location=no');
            return false;
        }
    }
    export default new SidenavIndexController('sidenavIndex' , { indexItem, sidenavIcon, customButton } );
</script>
<style scoped>

.name-container {
    
    padding-top: 50px;
    padding-bottom: 20px;
    font-size: calc(30px + 0.3vw);
    cursor: pointer;
    transition: 150ms;
}

.name-container:hover {

    transform: scale(1.1);
}

hr.solid {
  border-top: 3px solid #bbb;
}

.elements-container {
    padding-top: 25px;
}

.top-container {
    flex: 80%;
    overflow: auto;
}

.bottom-container {
    margin-bottom: 25px;
    width: 100%;
    align-content: center;
    flex: 15%;
}

.contact-title {

    font-family: YanoneKaffeesatz-Regular;
    text-align: justify;
    padding-left: 25px;
}

.contact-container {

    font-size: calc(12px + 0.3vw);
    
    font-family: YanoneKaffeesatz-Light;
    text-align: justify;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 25px;
}
    
.index-nav {
  
  height: 100%;
  width: clamp(200px, 15vh, 400px);
  min-width: 210px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  word-wrap: break-word;
  color: var(--color);
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3); 
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);
}

.icon {

    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
}


.resume-button {

    cursor: pointer;
}
</style>