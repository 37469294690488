<template>
  <section class="about">
     {{$route.path}}
  </section>
</template>

<script>
    import Controller from '@/../lib/controller'
    
    class ProjectViewController extends Controller {

        constructor( name, subComponentList = []) {

            super( name, subComponentList );

            this.vm = {

                
            }

            this.props = {

                title: String
            }
        }
    }

    export default new ProjectViewController('projectView'/* , { subComponent, anotherComponent } */);
</script>

<style scoped>

    .about {
        color: aliceblue;
    }
</style>