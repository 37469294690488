import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from '@/router'
import store from '@/store'

Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', event => {

  const app = new Vue({ router, store, components: { App }, render: h => h( App )})
  app.$mount('#app');
});