<!--
Copyright (C) 2020, Nicolas Morales Escobar. All rights reserved.
-->
<template>

    <section class="images-container">

        <div class="image-container">
            <div v-if="video != null && video != '' && this.imageIndex == 0"  class="project-video">
                <iframe :src="video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <img v-else class="project-picture" :src=currentImage>
        </div>

        <div class="buttons-container">
            <i class="fas fa-chevron-circle-left previous round image-button" @click="previousImage()"></i>
            <i class="fas fa-chevron-circle-right next round image-button" @click="nextImage()"></i>
        </div>
        
    </section>

</template>

<script>
    
    import Controller from '@/../lib/controller'
    
    class ProjectImageController extends Controller {

        constructor( name, subComponentList = []) {

            super( name, subComponentList );

            this.vm = {

            }
            
            this.data = function() {
                return {
                    imageIndex: 0
                }
            }

            this.props = {
                
                images: Array,
                video: String
            }

            this.computed = {
                currentImage: function() {

                    let index = this.imageIndex;
                    
                    if (this.video != null && this.video != '') {
    
                        index--;
                    }
                    
                    if (index < this.images.length) {
                        
                        return this.images[index]
                    }

                    return '';
                }

            }
        }
        
        nextImage () {
            
            this.imageIndex++;

            if (this.video != null && this.video != '') {
    
                if (this.imageIndex > this.images.length) {
                    
                    this.imageIndex = 0;
                }
            }
            else if (this.imageIndex >= this.images.length) {
                                
                this.imageIndex = 0;
            }
        }

        previousImage () {

            this.imageIndex--;
            
            if (this.video != null && this.video != ''){

                if (this.imageIndex < 0 && this.images.length > 0) {

                this.imageIndex = this.images.length;
                }
            }
            else if (this.imageIndex < 0 && this.images.length > 0) {

                this.imageIndex = this.images.length - 1;
            }
        }
    }


    export default new ProjectImageController('projectImage'/* , { subComponent, anotherComponent } */);
</script>

<style scoped>

    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .project-picture {
            
        width: 100%;
        height: auto;
        border-radius: 25px;
    
        -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3); 
        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);
    }

    .project-video {
        
        width: 100%;
        height: 0;
        min-height: 0;
        padding-top: 56.7%;
        position: relative;
        
        border-radius: 25px;
    
        -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3); 
        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);
        overflow: hidden;
    }

    .images-container {
        
        position: relative;
    }

    .image-container {

        
    }

    .buttons-container {

        position: absolute;

        top: 47.5%;

        width: 100%;
        z-index: 10;
    }

    .previous {

        left: 5%;
    }

    .next {

        right: 5%;
    }

    .image-button {
        cursor: pointer;
        position: absolute;
        
        color: #cccac677;

        font-size: calc(20px + 1vw);

        transition: 100ms;
    }

    .image-button:hover {
        
        color: #CCCAC6;;

        transform: scale(1.2);
    }

    .round {
        border-radius: 50%;
    }

</style>