<template>
  <section>
    <div class="container">
      
      <div class="main-container">
        <bio class="bio-container" :imgSrc="profile.imgSrc" :name="profile.name" :role="profile.role" :description="profile.description"></bio>
        
        <project-summary v-for="(item, index) in projects" :key="index" 
                            :title="item.name"
                            :description="item.description"
                            :tags="item.tags"
                            :imgSrc="item.imgSrc"
                            :workItems="item.workItems"
                            :downloadLink="item.downloadLink"
                            :video="item.videoLink">
        </project-summary>

        <separator :name="'Other Projects'"></separator>
        
        <div class="other-projects-container"
                            :ref="'OtherProjects'"
                            :id="'OtherProjects'">
          
          <project-preview v-for="(item, index) in otherProjects" :key="index" 
                                :title="item.name" 
                                :imgSrc="item.imgSrc" 
                                :description="item.description" 
                                :bgColor="'#0A2845'"
                                :tags="item.tags"
                                :link="item.repo">
          </project-preview>
        </div>

        <div class="copyright" :ref="'Copyright'"> Copyright (C) 2020, {{profile.name}}. All rights reserved. </div>
      </div>
    </div>

    <top-navbar class="topbar-container" :bgColor="colors.sidenavBG" :fontColor="colors.sidenavFontColor"
                :githubLink="links.github" :linkedinLink="links.linkedin" :email="profile.email"></top-navbar>

    <sidenav-index  class="sidenav-container" :name="profile.name" :githubLink="links.github" :linkedinLink="links.linkedin" 
                    :email="profile.email" :place="profile.place" :resume="links.resume" :elements="projects" :extraItems="extraItems"
                    :bgColor="colors.sidenavBG" :baseIconColor="colors.sidenavIconsColor" :fontColor="colors.sidenavFontColor"
                    v-on:scroll="scrollTo($event)">
    </sidenav-index>

  </section>
</template>

<script>
    import bio from '@/components/BioComponent.vue'
    import separator from '@/components/SeparatorComponent.vue'
    import projectSummary from '@/components/ProjectSummaryComponent.vue'
    import projectPreview from '@/components/ProjectPreview.vue'
    import sidenavIndex from '@/components/SidenavIndex.vue'
    import topNavbar from '@/components/TopNavbar.vue'

    import profileImg from "@/assets/images/nicolas-photo.jpg"

    import MythForceImg from "@/assets/images/MythForce_Menu.png"
    import MythForceImg1 from "@/assets/images/MythForce_Entry.png"
    import MythForceImg2 from "@/assets/images/MythForce_Skeletons.png"
    import MythForceImg3 from "@/assets/images/MythForce_Horde.png"
    import MythForceImg4 from "@/assets/images/MythForce_Beastor1.png"

    import terminalVelocityImg from "@/assets/images/tv-8.png"
    import terminalVelocityImg1 from "@/assets/images/tv-9.png"
    import terminalVelocityImg2 from "@/assets/images/tv-1.png"
    import terminalVelocityImg3 from "@/assets/images/tv-2.png"
    import terminalVelocityImg4 from "@/assets/images/tv-3.png"
    import terminalVelocityImg5 from "@/assets/images/tv-7.png"
    import terminalVelocityImg6 from "@/assets/images/tv-4.png"

    import ascendImg from "@/assets/images/ascend1.png"
    import ascendImg1 from "@/assets/images/Ascend-Photo.png"
    import ascendImg2 from "@/assets/images/ascend2.png"
    import ascendImg3 from "@/assets/images/ascend3.png"
    import ascendImg4 from "@/assets/images/ascend4.png"

    import pastaPartyImg from "@/assets/images/pastaparty1.jpg"
    import pastaPartyImg1 from "@/assets/images/pastaparty2.jpg"
    import pastaPartyImg2 from "@/assets/images/pastaparty3.jpg"
    import pastaPartyImg3 from "@/assets/images/pastaparty4.jpg"
    import pastaPartyImg4 from "@/assets/images/PastaParty-Photo.jpg"

    import editorImg from "@/assets/images/editor-photo.png"
    import missileImg from "@/assets/images/missile-photo.png"
    import networkImg from "@/assets/images/network-photo.png"
    import pongImg from "@/assets/images/pong-photo.png"
    import shadersImg from "@/assets/images/shaders-photo.jpg"

    import Controller from '@/../lib/controller'

    class HomeController extends Controller {

        constructor( name, subComponentList = []) {

            super( name, subComponentList );

            this.vm = {
              profile: {
                name: 'Nicolas Morales Escobar',
                imgSrc: profileImg,
                description: 'I am originally from Colombia and I have been interested in game development since I was 11 years old. Now, I am excited for having the opportunity to apply for a career in the Canadian videogame industry. Since I officially started my student career, I have been known as a hardworking developer who is always seeking to improve his knowledge/skills while trying help others do the same.',
                role: 'Gameplay Programmer',
                email: 'nico96101@hotmail.com',
                place: 'Vancouver, BC'
              },
              links: {
                github: 'https://github.com/Nicolas4677',
                linkedin: 'https://www.linkedin.com/in/nicolas-morales-escobar',
                resume: 'https://firebasestorage.googleapis.com/v0/b/nicolas-portfolio.appspot.com/o/Resume%20-%20Nicolas%20Morales%20Escobar.pdf?alt=media&token=d443484e-eaa7-467f-a14d-b3d75814f7c0'
              },
              projects: [
                {
                  name: 'MythForce',
                  imgSrc: [ MythForceImg, MythForceImg1, MythForceImg2, MythForceImg3, MythForceImg4 ],
                  description: "MythForce is a Rogue-Lite Dungeon Crawler First Person online multiplayer co-op game where up to 4 players get to choose a unique hero to fight the forces of Deadalus. Every match will be different, so, players will need to adapt their character and skills to be able to defeat the deadliest foes.",
                  tags: [ { name: "UE4", color: "#ff1744"}, { name: "C++", color: "#2979ff"}],
                  workItems: ['Collaborated with multiple teams to create/improve gameplay features', 'Implemented multiple NPCs into the game including Regular enemies, Elites and Bosses', 'Improved AI behaviour and abilities', 'Networking to synchronize state for all players', 'Maintained and tested feature code'],
                  downloadLink: 'https://mythforce.com/',
                  videoLink: 'https://www.youtube.com/embed/3DCvHre1NVE'
                },
                {
                  name: 'Terminal Velocity',
                  imgSrc: [terminalVelocityImg, terminalVelocityImg1, terminalVelocityImg2, terminalVelocityImg3, terminalVelocityImg4, terminalVelocityImg5, terminalVelocityImg6],
                  description: "Terminal Velocity is an Online FPS Racing Game where up to 4 players take on the role of cosmic mercenaries who go head to head in a race across galaxies! Players will have to utilize their omni-weapon to kill enemies in order to gain speed, create their own paths and harass their opponents to be crowned the winner!",
                  tags: [ { name: "UE4", color: "#ff1744"}, { name: "C++", color: "#2979ff"}, { name: "Firebase", color: "#B8841D"} ],
                  workItems: ['All player mechanics', 'All enemies AI behavior', 'Networking to synchronize state for all players', 'Interactive environment', 'VFX implementation', 'Tool to synchronize gameplay data in the cloud'],
                  downloadLink: 'http://community.vfs.com/arcade/game/terminal-velocity/',
                  videoLink: 'https://www.youtube.com/embed/gkhdsbNi-EQ'
                },
                {
                  name: 'Pasta Party',
                  imgSrc: [pastaPartyImg, pastaPartyImg1, pastaPartyImg2, pastaPartyImg3, pastaPartyImg4],
                  description: 'Pasta Party is a Mobile 3D third-person endless runner where the player, who impersonates the Flying Spaghetti Monster, has to fly away from any obstacle moving towards him. Every obstacle in the game is represented by a singular deity.',
                  tags: [ { name: "Unity", color: "#ff1744"}, { name: "C#", color: "#2979ff"}, { name: "Shader Lab", color: "#2979ff"} ],
                  workItems: ['All player mechanics', 'All enemies AI behavior', 'Custom shaders with Shader Lab', 'UI implementation','Custom mobile dynamic joystick', 'Character customization system', 'Tool for a character index/glossary menu'],
                  downloadLink: '',
                  videoLink: null
                }
              ],
              otherProjects: [
                  {
                    name: 'Ascend',
                    imgSrc: ascendImg2,
                    description: 'Game about character with multiple abilities that needs to fight different enemies every round. Has controller support both for menu and gameplay.',
                    tags: [ { name: "Unity", color: "#ff1744"}, { name: "C#", color: "#2979ff"} ],
                    repo: ''
                  },
                  {
                    name: 'Angry Pigs with Level Editor ',
                    imgSrc: editorImg,
                    description: 'Game with a built in level editor where the developer can create custom levels and save them.',
                    tags: [ { name: "JavaScript", color: "#2979ff"}, { name: "HTML", color: "#2979ff"}, { name: "CSS", color: "#2979ff"} ],
                    repo: 'https://github.com/Nicolas4677/JavaScript-Level-Editor.git'
                  },
                  {
                    name: 'Custom Networking in Unity',
                    imgSrc: networkImg,
                    description: 'Custom networking system where multiple players can connect to a headless server and interact with other players through chat or the in-game character.',
                    tags: [ { name: "Unity", color: "#ff1744"}, { name: "C#", color: "#2979ff"}],
                    repo: 'https://github.com/Nicolas4677/Custom-Networking-System.git'
                  },
                  {
                    name: 'Missile Command Prototype',
                    imgSrc: missileImg,
                    description: 'Small prototype of the Missile Command game made with a third-party engine called EngineX.',
                    tags: [ { name: "C++", color: "#2979ff"} ],
                    repo: 'https://github.com/Nicolas4677/Missile-Command-Prototype-EngineX.git'
                  },
                  {
                    name: 'Pong Prototype',
                    imgSrc: pongImg,
                    description: 'Small prototype of the Pong game made with a third-party engine called EngineX.',
                    tags: [ { name: "C++", color: "#2979ff"} ],
                    repo: 'https://github.com/Nicolas4677/Pong-Prototype-EngineX.git'
                  },
                  {
                    name: 'Custom Shaders',
                    imgSrc: shadersImg,
                    description: 'Unity project with multiple surface and vertex shaders.',
                    tags: [ { name: "Unity", color: "#ff1744"}, { name: "C#", color: "#2979ff"}, { name: "Shader Lab", color: "#2979ff"} ],
                    repo: 'https://github.com/Nicolas4677/Unity-Custom-Shaders.git'
                  },
              ],
              extraItems: [
                {
                  name: 'Other Projects'
                }
              ],
              colors: {
                sidenavBG: '#0A2845',
                sidenavIconsColor: 'rgb(228, 228, 228)',
                sidenavFontColor: '#CCCAC6'
              },
            }

            this.methods = {
              scrollTo: function(id) {
                      
                var container = this.$el.querySelector(`#${id}`);
                window.scrollTo({ top: container.offsetTop, behavior: 'smooth' });
              }
            }
        }

        scrollTo (id) {
          
        }
    }
    
    export default new HomeController('Home' , { bio, separator, projectSummary, sidenavIndex, projectPreview, topNavbar });
</script>

<style scoped>

.container {
    padding-left: 200px;
    margin: 0px;
    grid-gap: 0px;
}

.bio-container {

}

.copyright {
    /*background-color: rgb(20, 20, 20);*/
    background-color:  #0A2845;
    padding-top: 50px;
    padding-bottom: 10px;
    font-size: 18px;
    
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3); 
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);
}

.sidenav-container {
    /*background-color: rgb(20, 20, 20);*/
    background-color: #0A2845;
    min-width: 200px;
}

.topbar-container {

  display: none;
}

.main-container {
    /*background-color: #1f1f1f;*/
    background-color: rgb(28, 31, 32);
    color: #CCCAC6;
}

.other-projects-container {

    padding: 0px 50px 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 100px;
    
}

@media screen and (max-width: 991px){        
    
    .sidenav-container {
        display: none;
    }

    
    .topbar-container {

        display:flex;   
    }
    .other-projects-container {

      grid-template-columns: repeat(1, 1fr);
    }

    .container {
      padding-left: 0px;
    }

    .bio-container {
      margin-top: 50px;
    }
}

</style>