<!--
Copyright (C) 2020, Nicolas Morales Escobar. All rights reserved.
-->
<template>

    <span class="skillTag" :style="{ 'background-color': color }">{{title}}</span>

</template>
<script>
    import Controller from '@/../lib/controller'
    
    class SkillTagController extends Controller {
        constructor( name, subComponentList = []) {
            
            super( name, subComponentList );

            this.vm = {

            }

            this.props = {
                title: String,
                color: String
            }
        }
    }
    export default new SkillTagController('skillTag'/* , { subComponent, anotherComponent } */);
</script>
<style scoped>
    
.skillTag {
    border-radius: 10px;
    padding: 5px 10px;
    margin: 5px;
    -webkit-box-shadow: inset 0px 0px 20px -1px rgba(0,0,0,0.5); 
    box-shadow: inset 0px 0px 20px -1px rgba(0,0,0,0.5);
}
</style>