<!--
Copyright (C) 2020, Nicolas Morales Escobar. All rights reserved.
-->
<template>

    <span>
        <i :class="iconClass" :style="currentIconColor" @click="execute()"></i>
    </span>

</template>
<script>
    import Controller from '@/../lib/controller'
    
    class SidenavIconController extends Controller {

        constructor( name, subComponentList = []) {
            
            super( name, subComponentList );

            this.vm = {

            }
            this.props = {
                iconType: String,
                baseIconColor: String,
                hoverColor: String
            }

            this.computed = {
                currentIconColor: function() {
                    return {
                        '--color': this.baseIconColor,
                        '--color-hover': this.hoverColor
                    }
                },
                iconClass: function() {
                    return this.iconType + " icon"
                }
            }
        }
        
        execute () {
            
            this.$emit('clicked');
        }
    }
    export default new SidenavIconController('sidenavIcon'/* , { subComponent, anotherComponent } */);
</script>
<style scoped>

.icon {
    color: var(--color);
    margin-left: 10px;
    margin-right: 10px;
    opacity: 0.8;
    transition: 100ms;
    
    font-size: calc(40px + 0.3vw);
}

.icon:hover {
    color: var(--color-hover);
    cursor: pointer;
    
    opacity: 1;
    transform: scale(1.2);
}

</style>