<!--
Copyright (C) 2020, Nicolas Morales Escobar. All rights reserved.
-->
<template>

    <section class="item glow" :style="itemDisplay" @mouseover="hovered = true" @mouseout="hovered = false" @click="execute()">
        <span :style=" { 'visibility': iconDisplay }">
            <i class="fas fa-code icon"></i>
        </span>
        <span>
            {{title}}
        </span>
    </section>

</template>

<script>
    import Controller from '@/../lib/controller'
    
    class IndexItemController extends Controller {

        constructor( name, subComponentList = []) {

            super( name, subComponentList );

            this.vm = {
                
            }

            this.props = {

                title: String,
                target: String
            }

            this.data = function() {
                return {
                    hovered: false
                }
            }

            this.computed = {
                iconDisplay : function() {
                    return this.hovered ? 'visible' : 'hidden';
                },
                itemDisplay : function() {
                    return {
                        '--color' : '#CCCAC6',
                        '--color-hover' : '#CCCAC6',
                        '--font-size': '15px',
                        '--font-size-hover': '18px',
                    }
                }
            }
        }

        execute () {
            
            this.$emit('clicked');
        }
    }

    export default new IndexItemController('indexItem'/* , { subComponent, anotherComponent } */);
</script>

<style scoped>

.item {
    
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;

    cursor: pointer;
    text-align: left;
    transition: 250ms;
    font-family: YanoneKaffeesatz-Light;
    color: var(--color);

    min-height: 30px;
    
    font-size: calc(var(--font-size) + 0.3vw);
}

.item:hover {
    color: var(--color-hover);
    font-size: calc(var(--font-size-hover) + 0.3vw);
}

.icon {

    font-size: calc(12px + 0.2vw);
    padding-right: 15px;
}

</style>