<!--
Copyright (C) 2020, Nicolas Morales Escobar. All rights reserved.
-->
<template>

    <section :id="newId">
        <separator v-bind:name="title"></separator>
        <div class="project-container">
            <div class="info info-container">
                <div class="info-title">Description:</div>
                <div class="info-description description-container">{{description}}</div>
                <div class="info-title ">What I did:</div>
                <div class="description-container">
                    <div class="info-description role" v-for="(item, index) in workItems" :key="index">• {{item}}</div>
                </div>
                <div class="info-title">Technologies:</div>
                <div class="info-description description-container">
                    <skill-tag v-for="(item, index) in tags" :key="index" 
                            :title="item.name"
                            :color="item.color">
                    </skill-tag>
                </div>
                
                <!--<router-link class="info-title learn-more" :to="{ name: 'Project', params: { project: newId, title: title } }">Learn more...</router-link>-->
            </div>
            <div class="picture-container">
                <project-image class="picture" :images="imgSrc" :video="video"></project-image>
                <div class="download-container" v-if="downloadLink != null && downloadLink != ''">
                    <button-component class="info-title" :text="'Download'"  v-on:clicked="openLink(downloadLink)"></button-component>
                </div>
            </div>
        </div>
    </section>
    

</template>
<script>
    import Controller from '@/../lib/controller'
    import separator from '@/components/SeparatorComponent.vue'
    import projectImage from '@/components/ProjectImage.vue'
    import skillTag from '@/components/SkillTag.vue'
    import buttonComponent from '@/components/ButtonComponent.vue'
    
    class ProjectSummaryController extends Controller {

        constructor( name, subComponentList = []) {

            super( name, subComponentList );

            this.vm = {

                someData: "Hello world"
            };

            this.props = {

                title: String,
                imgSrc: Array,
                description: String,
                tags: Array,
                workItems: Array,
                downloadLink: String,
                video: String
            };

            this.computed = {

                newId: function() {

                    return this.title.replace(/\s+/g, '');
                }
            }
        }
        
        openLink (link) {

            window.open(link);
        }
    }

    export default new ProjectSummaryController('projectSummary', { separator, skillTag, projectImage, buttonComponent } );
</script>
<style scoped>

.project-container {

    display: grid;
    grid-template-areas: 'description-layout picture-layout';
    grid-template-columns: 50% auto;
    padding: 0px 50px;
}

.info-container {
    text-align: left;
}

.info {

  grid-area: 'info-layout';
}

.info-title {

    text-align: left;
    font-size: calc(25px + 0.5vw);
}

.picture-container {
    
  grid-area: 'picture-layout';
}

.download-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: calc(20px + 1vh);
}

.picture {

}

.info-description {

    font-family: YanoneKaffeesatz-Light;
}

.description-container {
    padding: 25px 50px 25px 0px;
}

.role {
    margin-bottom: 10px;
    padding-left: 25px;
}

.learn-more {
    color: #CCCAC6;
}

@media screen and (max-width: 1000px){        
    
    .project-container {
        
        grid-template-areas:
        'description-layout'
        'picture-layout';

        grid-template-columns: repeat(1, 1fr);    
    }

    #project-picture {
        
        width: 100%;
        height: auto;
    }

    .picture {
        
        margin: 20px 0px;
    }
}
</style>