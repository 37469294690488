<!--
Copyright (C) 2020, Nicolas Morales Escobar. All rights reserved.
-->
<template>

    <section class="nav-container" :style="barStyle">
        <div class="left-container" @click="scrollToTop()">
            <div class="name-container">

                Nicolas Morales <br> Escobar
            </div>
        </div>

        <div class="right-container">
            
            <div>
                <sidenav-icon :iconType="icons.github.type" :hoverColor="icons.github.hoverColor" v-on:clicked="openLink(githubLink)"></sidenav-icon>
                <sidenav-icon :iconType="icons.linkedin.type" :hoverColor="icons.linkedin.hoverColor" v-on:clicked="openLink(linkedinLink)"></sidenav-icon>
                <sidenav-icon :iconType="icons.mail.type" :hoverColor="icons.mail.hoverColor" v-on:clicked="sendMail(email)"></sidenav-icon>
            </div>
        </div>
    </section>

</template>

<script>

    import Controller from '@/../lib/controller'
    import sidenavIcon from '@/components/SidenavIcon.vue'
    
    class TopNavbarController extends Controller {

        constructor( name, subComponentList = []) {

            super( name, subComponentList );

            this.vm = {

                icons: {
                    github: {
                        type: 'fab fa-github',
                        hoverColor: '#6f42c1'
                    },
                    linkedin: {
                        type: 'fab fa-linkedin',
                        hoverColor: '#2867B2'
                    },
                    mail: {
                        type: 'fas fa-envelope',
                        hoverColor: '#F0D734'
                    }
                }
            }

            this.props = {
            
                bgColor: String,
                fontColor: String,
                githubLink: String,
                linkedinLink: String,
                email: String,
            }

            this.computed = {

                barStyle: function() {

                    return {
                        'background-color': this.bgColor,
                        'color': this.fontColor
                    }
                }
            }
        }

        openLink (link) {

            window.open(link);
        }

        sendMail (email) {

            window.location.href = `mailto:${email}`;
        }
        
        scrollToTop () {
            
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    export default new TopNavbarController('topNavbar' , { sidenavIcon } );
    
</script>

<style scoped>
    
    .nav-container {

        position: fixed;
        width: 100vw;
        height: calc(50px + 0.5vh);
        background-color: black;
        z-index: 10;
        top: 0;
        display: flex;
        
        -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3); 
        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);

        font-size: calc(18px + 0.5vw);
    }

    .left-container {
        flex: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .name-container {
        transition: 100ms;
    }

    .name-container:hover {

        cursor: pointer;
        transform: scale(1.1);
    }

    .right-container {
        flex: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
</style>