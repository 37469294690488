<template>
  <div id="app" class="container">
    
    <router-view/>
  </div>
</template>

<script>

    import Controller from '@/../lib/controller'

    class AppController extends Controller {
        constructor( name, subComponentList = []) {

            super( name, subComponentList );
            
            this.vm = {
                title: 'Portfolio',
                subtitle: ''
            }
        }
    }
    
    export default new AppController('PortfolioApp' /*, {  }*/);
</script>

<style>

#app {

  font-family: YanoneKaffeesatz-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color:rgb(228, 228, 228);*/
  color:rgb(20, 20, 20);
  font-size: calc(18px + 0.5vh);
}

body {

  background-color: rgb(28, 31, 32);
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: 'RobodoCondensed-Regular';
  src: url('../content/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf')
}

@font-face {
  font-family: 'RobodoCondensed-BoldItalic';
  src: url('../content/fonts/Roboto_Condensed/RobotoCondensed-BoldItalic.ttf');
}

@font-face {
  font-family: 'RobodoCondensed-Bold';
  src: url('../content/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf');
}

@font-face {
  font-family: 'RobodoCondensed-Italic';
  src: url('../content/fonts/Roboto_Condensed/RobotoCondensed-Italic.ttf');
}

@font-face {
  font-family: 'RobodoCondensed-LightItalic';
  src: url('../content/fonts/Roboto_Condensed/RobotoCondensed-LightItalic.ttf');
}

@font-face {
  font-family: 'RobodoCondensed-Light';
  src: url('../content/fonts/Roboto_Condensed/RobotoCondensed-Light.ttf');
}

@font-face {
  font-family: 'YanoneKaffeesatz-Bold';
  src: url('./assets/fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-Bold.ttf');
}

@font-face {
  font-family: 'YanoneKaffeesatz-ExtraLight';
  src: url('./assets/fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-ExtraLight.ttf');
}

@font-face {
  font-family: 'YanoneKaffeesatz-Light';
  src: url('./assets/fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-Light.ttf');
}

@font-face {
  font-family: 'YanoneKaffeesatz-Medium';
  src: url('./assets/fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-Medium.ttf');
}

@font-face {
  font-family: 'YanoneKaffeesatz-Regular';
  src: url('./assets/fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-Regular.ttf');
}

@font-face {
  font-family: 'YanoneKaffeesatz-SemiBold';
  src: url('./assets/fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-SemiBold.ttf');
}
</style>