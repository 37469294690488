<!--
Copyright (C) 2020, Nicolas Morales Escobar. All rights reserved.
-->
<template>

    <div class="separator">
        <div class="horizontal-flex name-text inner-color">  
            {{name}}
        </div>
    </div>

</template>
<script>
    import Controller from '@/../lib/controller'

    class SeparatorController extends Controller {

        constructor( name, subComponentList = []) {

            super( name, subComponentList )

            this.vm = {
                
            }

            this.props = {
                name: String,
                height: Number,
                color: String
            }
        }
    }
    export default new SeparatorController('separator'/* , { subComponent, anotherComponent } */);
</script>
<style scoped>
  
.separator {
    background-color: #090E1C;
    height: 80px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.horizontal-flex {
    display: flex;
    flex-direction: column;
    flex: 100%;
    height: 100%;
    justify-content: center;
}

.name-text {
    font-size: calc(30px + 0.5vw);
    color: rgb(228, 228, 228);
}

.inner-color {
    background-color: #0A2845;
    
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3); 
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);
    
    
}

</style>