<!--
Copyright (C) 2020, Nicolas Morales Escobar. All rights reserved.
-->
<template>

    <section>
        <img id="main-photo" v-bind:src=imgSrc>
        <div>
            <p id="name"> Nicolas Morales Escobar </p>
            <p id="role"> Gameplay Programmer </p>
        </div>
        <div>
            <p id="description">
                I am a highly motivated Game Developer specialized in gameplay. I have a Bachelor's Degree on Engineering of Digital Entertainment Design at <span class="keyword">Pontifical Bolivarian University</span>, 
                as well as an Honors Diploma in Programming for Games, Web & Mobile at <span class="keyword">Vancouver Film School</span>. These programs and my professional experience have 
                given me a wide expertise in different technologies and methodologies in Game Development, including a proficient use of <span class="keyword">Unreal Engine</span>, <span class="keyword">Unity</span>,
                <span class="keyword">C++</span> and <span class="keyword"> C#</span>.
                <br>
                <br>
                Throughout the collaborative teams that I have contributed towards,  I have always strived to learn and share with others, seeking to improve my abilities and expertise while bettering 
                those around me. My passion is to <span class="keyword">solve problems</span> through code by creating readable, reusable and approachable work that follows <span class="keyword">SOLID principles</span>. 
            </p>
        </div>
    </section>

</template>
<script>
    import Controller from '@/../lib/controller'

    class BioController extends Controller {

        constructor( name, subComponentList = []) {

            super( name, subComponentList )

            this.vm = {
                
            }
            this.props = {
                imgSrc: String
            }
        }
    }
    export default new BioController('bio'/* , { subComponent, anotherComponent } */);
</script>
<style scoped>

.keyword {
    
    font-family: RobodoCondensed-Regular;
    color: #e0e0e0;
}

#main-photo {
    width: 250px;
    height: auto; 
    border-radius: 50px;
    margin-top: 50px;
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3); 
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);
}

#name {
    font-size: calc(40px + 0.5vw);
    margin-bottom: 0px;
}

#role {
    font-size: calc(25px + 0.5vw);
    margin: 0px;
}

#description {
    padding-left: 25%;
    padding-right: 25%;
    text-align: left;
    font-family: YanoneKaffeesatz-Light;
    
    line-height: 1.2;
}

@media screen and (max-width: 1000px){        
    
    #description {
    padding-left: 15%;
    padding-right: 15%;
    }
}

</style>