<!--
<some-tag-name title="My Component">
    -- user can add their own HTML code here, replaces 'slot' component --
</some-tag-name>
@copyright (c) 2019. Scott Henshaw. All Rights Reserved.
-->
<template>

    <section class="button-container">
        <button class="button" @click="execute()">{{text}}</button>
    </section>

</template>

<script>
    import Controller from '@/../lib/controller'

    class ButtonController extends Controller {

        constructor( name, subComponentList = []) {

            super( name, subComponentList );

            this.vm = {
                someData: "Hello world"
            }

            this.props = {
                text : String
            }
        }
        
        execute () {
            
            this.$emit('clicked');
        }
    }
    export default new ButtonController('customButton'/* , { subComponent, anotherComponent } */);
</script>

<style scoped>
.button {
    display: inline-block;
    border-radius: 8px;
    
    text-align: center;
    font-size: calc(12px + 0.3vw);
    padding: 10px 15px;
    transition: all 200ms;
    cursor: pointer;
    
    background-color: rgb(28, 31, 32);
    color: #CCCAC6;
    border: 2px solid  #CCCAC6;

    font-weight: bold;

    text-transform: uppercase;

    
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3); 
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.3);
}

.button:hover {
    background-color: #CCCAC6;
    color:rgb(28, 31, 32);
    border: 2px solid  rgb(28, 31, 32);

    transform: scale(1.2);
}

.button-container {
    
}

</style>