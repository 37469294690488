// Copyright (C) 2020, Nicolas Morales Escobar. All rights reserved.

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use( Vuex )


const store = new Vuex.Store({ 

    state: {

    }, mutations: {

    }, actions: {

    }, getters: {

    }})
export default store;